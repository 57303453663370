<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  created() {
    const userAgentInfo = navigator.userAgent;
    const mobileAgents = [
      "Android",
      "iPhone",
      "SymbianOS",
      "Windows Phone",
      "iPad",
      "iPod",
    ];
    const mobileFlag = mobileAgents.some((mobileAgent) => {
      return userAgentInfo.indexOf(mobileAgent) > 0;
    });
    if (!mobileFlag) {
      window.location.href =
        "https://www.chengzijianzhan.com/tetris/page/7392878532009001011/";
    }
  },
};
</script>

<style>
.margin-top-20 {
  margin-top: 20px;
}

.margin-top-15 {
  margin-top: 15px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-5 {
  margin-top: 5px;
}

.color-red {
  color: red;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-13 {
  font-size: 13px;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-16 {
  font-size: 16px;
}

.font-size-18 {
  font-size: 18px;
}

.font-size-22 {
  font-size: 22px;
}

.font-blod {
  font-weight: bold;
}

.justify-content {
  display: flex;
  justify-content: center;
}

html {
  background-color: #e6e6e6;
}

.ql-editor-box {
  width: 94%;
  margin: 0 auto;
}

.ql-editor {
  height: auto;
}
</style>

import Vue from 'vue'
import App from './App.vue'
import Vant from 'vant';
import 'vant/lib/index.css';
import router from './router'
import * as  api from './api';
import apiList from './api/apiList';
import * as  utils from './utils';
import wxJsApi from './utils/wxJsApi.js';


Vue.prototype.$api = api
Vue.prototype.$apiList = apiList
Vue.prototype.$utils = utils
Vue.prototype.$wxJsApi = wxJsApi;

Vue.config.productionTip = false
Vue.use(Vant);


new Vue({
  router,
  render: h => h(App),
}).$mount('#app')


/* eslint-disable */
import wx from 'weixin-js-sdk';
import * as  api from "../api";


// 设置默认的分享信息，因为如分享图、请求授权项、隐藏授权项等基本不变，此处设置直接引用，避免使用时繁琐设置
const defaultWxShareConfig = {
    title: "",//分享标题（通常是动态的）
    desc: '',//分享描述（通常是动态的）
    link: location.href,
    imgUrl: '',//分享图片（通常是128*128的logo）
    jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData']
}


const wxJsApi = {
    //存储服务器授权链接 
    url: 'https://site.gddtbz.com/',
    apiList: {
        sign: "api/gzh/sign",
        snsapiBase: "api/gzh/snsapiBase"
    },
    //存储各个链接的签名信息
    signMap: {},
    //存储临时的分享信息
    wxShareConfig: {

    },
    //各个页面加载后，调用此方法，传入的参数config是对象，其属性参考defaultWxShareConfig
    async updateWxShareConfig(config = {}) {
        this.wxShareConfig.title = config.title || defaultWxShareConfig.title;
        this.wxShareConfig.desc = config.desc || defaultWxShareConfig.desc;
        this.wxShareConfig.link = config.link || defaultWxShareConfig.link;
        this.wxShareConfig.imgUrl = config.imgUrl || defaultWxShareConfig.imgUrl;
        this.wxShareConfig.jsApiList = config.jsApiList || defaultWxShareConfig.jsApiList;
        this.wxShareConfig.hideMenuList = config.hideMenuList || defaultWxShareConfig.hideMenuList;

        //微信中二次分享的处理，截取到有效的分享链接
        var authUrl = this.wxShareConfig.link;
        //判断是否已经签名了
        if (!("authUrl" in this.signMap)) {
            await this._wxShareAuth(authUrl);
        }
        this._wxConfigJSSDK(this.signMap[authUrl]);
    },

    //从服务器获取某分享链接的签名信息，并存储起来
    async _wxShareAuth(authUrl, type = '') {
        //此处我使用的是自己封装的网络请求方法
        const res = await api.get(this.url + this.apiList.sign, {
            url: authUrl,
            page: "郑州慈善"
        });
        //此处请根据各自的服务器返回数据文档进行操作
        if (res.code == 0) {
            //分享链接授权签名信息
            delete res.code
            if (type == 'appid') {
                return res
            } else {
                this.signMap[authUrl] = res
            }
        }
    },
    //将签名信息更新到微信的SDK中
    _wxConfigJSSDK(shareSign) {

        wx.config({
            debug: false,
            appId: shareSign.appId,
            timestamp: shareSign.timestamp,
            nonceStr: shareSign.nonceStr,
            signature: shareSign.signature,
            jsApiList: shareSign.jsApiList
        })
        // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
        wx.ready(function () {
            console.log(this.wxShareConfig, '打印分享信息');

            const { title, desc, link, imgUrl } = this.wxShareConfig;
            wx.onMenuShareAppMessage({
                title,
                desc,
                link,
                imgUrl,
                success: function () {
                    console.log("分享成功");
                },
                fail: function () {
                    console.log("分享失败");
                },
                cancel: function () {
                    console.log("取消分享");
                }
            })
            wx.updateAppMessageShareData({
                title,
                desc,
                link,
                imgUrl,
                success: function () {
                    console.log("分享成功");
                },
                fail: function () {
                    console.log("分享失败");
                },
                cancel: function () {
                    console.log("取消分享");
                }
            })
            wx.onMenuShareTimeline({
                title,
                link,
                imgUrl,
                success: function () {
                    console.log("分享成功");
                },
                cancel: function () {
                    console.log("取消分享");
                }
            })
            wx.updateTimelineShareData({
                title,
                link,
                imgUrl,
                success: function () {
                    console.log("分享成功");
                },
                cancel: function () {
                    console.log("取消分享");
                }
            })


        });

        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。	
        wx.error(function (res) {
            console.log("分享失败: error", res);
        });

    },
    isWeChatEnvironment() {
        var ua = navigator.userAgent.toLowerCase(); //获取User-Agent字符串

        if (ua.match(/MicroMessenger/i) == "micromessenger") {
            return true; //如果包含"MicroMessenger"则表示在微信内置浏览器中打开
        } else {
            return false; //其他情况下返回false
        }
    },
    async snsapiBaseFun(code) {
        //此处我使用的是自己封装的网络请求方法
        const res = await api.get(this.url + this.apiList.snsapiBase, {
            code
        });
        //分享链接授权签名信息
        return res
    }

}

//导出工具类
export default wxJsApi
